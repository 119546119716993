jQuery(document).ready(function ($) {
  /* ==================== Fonts ==================== */
  const WebFont = require("webfontloader");

  WebFont.load({
    google: {
      families: ["Figtree:400,600"],
    },
  });

  /* ==================== Menus ==================== */

  const mobileMenuToggle = document.querySelector(".navToggle"),
    mobileMenu = document.getElementById("mobileNav"),
    header = document.querySelector("#header"),
    subMenuItems = document.querySelectorAll(".menu-item-has-children"),
    mobileSubMenuItems = document.querySelectorAll(
      "#mobileNav .menu-item-has-children"
    ),
    adminBar = document.getElementById("wpadminbar");

  mobileSubMenuItems.forEach((element) => {
    element.addEventListener("click", toggleSubMenu);
  });

  function toggleSubMenu() {
    this.classList.toggle("active");
  }

  const setHeaderHeight = () => {
    let headerOffset = header.getBoundingClientRect().bottom;
    let adminBarHeight;
    if (adminBar) {
      adminBarHeight = adminBar.offsetHeight;
      header.style.top = `${adminBarHeight}px`;
    } else {
      adminBarHeight = 0;
    }
    if (headerOffset && adminBarHeight !== null) {
      mobileMenu.style.top = `${headerOffset - adminBarHeight}px`;
      mobileMenu.style.minHeight = `calc(100vh - ${headerOffset}px)`;
    }
  };

  // Initialize header and mobile menu height
  setHeaderHeight();

  // Listen for window resizing to update header and mobile menu height as screen size changes
  addEventListener("resize", (event) => {
    setHeaderHeight();
  });

  mobileMenuToggle.addEventListener("click", () => {
    mobileMenuToggle.classList.toggle("is-active");
    mobileMenu.classList.toggle("menu-open");
  });
});
